import path from 'path'
import { AppError } from './lib/errors'

export const env = {
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    SECRET: process.env.SECRET,
    GOOGLE_ID: process.env.GOOGLE_ID,
    GOOGLE_SECRET: process.env.GOOGLE_SECRET,
    DATABASE_URL: process.env.DATABASE_URL,
    TIKTOK_SESSION_IDS: process.env.TIKTOK_SESSION_IDS,
    LEMON_SQUEEZY_KEY: process.env.LEMON_SQUEEZY_KEY,
    ELEVENLABS_KEY: process.env.ELEVENLABS_KEY,
    OPENAI_KEY: process.env.OPENAI_KEY,
    GOOGLE_PROJECT_ID: process.env.GOOGLE_PROJECT_ID,
    GOOGLE_SERVICE_PRIVATE_KEY: process.env.GOOGLE_SERVICE_PRIVATE_KEY,
    GOOGLE_SERVICE_EMAIL: process.env.GOOGLE_SERVICE_EMAIL,
    FLY_TOKEN: process.env.FLY_TOKEN,
    PEXELS_KEY: process.env.PEXELS_KEY,
    REPLICATE_API_TOKEN: process.env.REPLICATE_API_TOKEN,
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
    RUNPOD_TOKEN: process.env.RUNPOD_TOKEN,
}

if (typeof window === 'undefined') {
    for (const k in env) {
        if (env[k] == null) {
            // console.error(env)
            throw new AppError(`Missing required ssr env var '${k}'`)
        }
    }
}

for (const k in env) {
    if (k.startsWith('NEXT_PUBLIC') && env[k] == null) {
        throw new AppError(`Missing required client env var '${k}'`)
    }
}

export const DOWNLOADS_COUNT_COOKIE = 'tiktoktts-downloads'

export const FREE_CREDITS = 1000

export type OrgLimits = {
    words: number
    seats: number
}

export const plansConfig = [
    {
        variantId: 52783,
        name: '1 hour',
        usd: 14,
        limits: { words: 8_000, seats: 1 },
    },
    {
        variantId: 50792,
        name: '2 hours',
        usd: 29,
        limits: { words: 16_000, seats: 3 },
    },
    {
        variantId: 50793,
        name: '10 hours',
        usd: 99,
        limits: { words: 80_000, seats: 10 },
    },
    // test mode
    { variantId: 38951, name: '1 hour', limits: { words: 3_000, seats: 1 } },
    { variantId: 38949, name: '2 hours', limits: { words: 1_000, seats: 3 } },
]

export const variantIdToCredits = Object.assign(
    {},
    ...plansConfig.map((x) => ({ [x.variantId]: x.limits.words })),
)

// export const variantIdToCredits = {
//     // production mode
//     52783: 8_000, // 1 hour
//     50792: 16_000, // 2 hour
//     50793: 80_000, // 10 hours
//     // test mode
//     38951: 3000,
//     38949: 1000,
// }
